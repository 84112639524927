<template>
    <v-row class="mx-1 my-7" cols="12" width="100%">
        <slot name="meta">
            <div></div>
        </slot>
        <v-row cols="12" style="min-width:100% !important;">
            <v-col cols="12" width="100%">
                <h3 v-if="contentData.Title" class="q-title text-center">{{contentData.Title}}</h3>
            </v-col>
        </v-row>
        <v-row cols="12" width="100%" wrap align="stretch" class="d-flex">
            <slot name="DataTables">
                <v-col v-for="(datatable, key) in contentData.DataTables" :key="key" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="">
                    <q-cms-data-table :datatable="datatable"></q-cms-data-table>
                </v-col>
            </slot>
        </v-row>
    </v-row>
</template>
<script>
import QCmsDataTable from './Elements/DataTable.vue'
export default {
    name: "DataTableGroup",
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    DataTables: [
                        {}
                    ],
                    title: null,
                    NumColumns: 4
                }
            }
        }
    },
    data: () => ({

    }),
    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.contentData.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.contentData.NumColumns);
        }
    },
    methods: {
        
    },
    components: {
        QCmsDataTable
    }
}

</script>
