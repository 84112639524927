<template>
    <v-card>
        <v-card-title>
            <h3 class="q-title">{{datatable.title}}</h3>
            <v-spacer v-if="datatable.title"></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table 
            :headers="the_headers" 
            :items="all_data"
            item-key="id"
            class="elevation-0"
            :footer-props="{itemsPerPageOptions:[10,25,50,100]}" 
            :loading="loading" 
            :options.sync="options" 
            :search="search"
            :no-data-text="noDataText"
        ></v-data-table>
    </v-card>
    
        
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    name: "DataTable",
    props: {
        datatable: {
            type: Object,
            default: function() {
                return {
                    title: null,
                    csvUrl: null,
                    filterOnAgentCode: null,
                    rankOnColumnName: null,
                    rankSortDirection: null
                }
            }
        }
    },
    data: () => ({
        isLoading: false,
        the_headers: [],
        search: '',
        options: {
                'sortBy': [], //used to set the default sorting column
                'sortDesc': [],
                'itemsPerPage': 25
            },
        the_data: [],
        error: ""
    }),
    mounted: function() {
        this.loadData()
        this.options.sortBy = [this.datatable.rankOnColumnName]
        this.options.sortDesc = [this.datatable.rankSortDirection === 'Descending' || this.datatable.rankSortDirection == null]
    },
    computed: {
        loading: function() {
            return this.isLoading;
        },
        all_data: function() {
            return this.the_data
        },
        noDataText: function() {
            return this.error != "" ? this.error : "No data available"
        }
    },
    methods: {
        loadData: function() {
            this.isLoading = true
            
            QuilityAPI.getCsvData(this.datatable.csvUrl, {
                convertToJson: true, 
                filterOnAgentCode: this.datatable.filterOnAgentCode,
                rankOnColumnName: this.datatable.rankOnColumnName,
                rankSortDirection: this.datatable.rankSortDirection
            })
                .then((json) => {
                    this.isLoading = false
                    if (typeof json.response != 'undefined' && json.response.clientError) {
                        this.showError(json.response.body.message);
                        return
                    }

                    //handle rank column if enabled
                    if(this.datatable.rankOnColumnName != null && json.headers.includes(this.datatable.rankOnColumnName)) {
                        //convert rankOnColumnName value to number
                        json.data.forEach(row => row[this.datatable.rankOnColumnName] = parseInt(row[this.datatable.rankOnColumnName]))

                        //sort
                        json.data.sort((a, b) => (a[this.datatable.rankOnColumnName] < b[this.datatable.rankOnColumnName]) ? 1 : -1)

                        //add a rank field
                        json.headers.unshift("Rank")

                        //add rank value to each row
                        for(var x=0; x<json.data.length; x++)
                            json.data[x]['Rank'] = "#"+(x+1)

                    }
                    
                    this.the_headers = json.headers.map(header => ({text:header, value:header}))
                    this.the_data = json.data
                    this.error = json.error
                }).catch((err) => {
                    this.isLoading = false
                    if (err.status == 431) {
                        this.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    this.showError(err.message);
                });
        },
    },
}
</script>
