export default {
    getImages(page, division, search) {
        var url = '/api/private/images?page=' + page + '&sortBy=created_at&sortDesc=true&division=' + division
        if(search !== undefined && search.length > 0)
            url += '&search='+search
        return this.getRequest(url).then(function(json) {
            return json
        })
    },
    deleteImage(id) {
        return this.deleteRequest('/api/private/images/' + id).then(
            function(json) {
                return json
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Image not found"
                } else {
                    msg = err.response.message
                }
                return msg
            })
    },
}
